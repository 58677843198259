<template>
  <div class="table table-time">
    <div class="cgdbh">采购单编号: {{purchaseOrderNumber}}</div>
    <el-table :data="newSelectTabData" ref="contentData" stripe style="width: 100%">
      <el-table-column prop="itemNumber" label="物料编号" :show-overflow-tooltip="true" width="100px"></el-table-column>
      <el-table-column prop="materialDescription" label="物料描述" :show-overflow-tooltip="true" width=""></el-table-column>
      <el-table-column prop="supplierName" label="供应商名称" :show-overflow-tooltip="true" width=""></el-table-column>
      <el-table-column prop="outstandingQuantity" label="未交数量" :show-overflow-tooltip="true" width="110px"></el-table-column>
      <el-table-column prop="zjhdsl" label="已做交货单数量" :show-overflow-tooltip="true" width="120px"></el-table-column>
      <el-table-column prop="deliveryQuantity" label="交付数量" :show-overflow-tooltip="true" width="110px">
        <template #default="scope">
          <el-input v-model="scope.row.deliveryQuantity" @input="(val)=>{scope.row.deliveryQuantity = val.replace(/\D|^[0]+/g,'')}" placeholder="请输入"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="demandDate" label="需求日期" :show-overflow-tooltip="true" width="120px"></el-table-column>
      <el-table-column prop="actualDeliveryDate" label="原交付日期" :show-overflow-tooltip="true" width="120px"></el-table-column>
      <el-table-column prop="newDeliveryDate" label="实际交付日期" :show-overflow-tooltip="true" width="160">
        <template #default="scope">
          <el-date-picker @focus="dateFocus(scope.row)" @change="dateChange(scope.row)" v-model="scope.row.newDeliveryDate" type="date" placeholder="日期"></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column v-if="havebz || true" prop="updateDeliveryTimeReason" label="交期更改原因" :show-overflow-tooltip="true" width="160">
        <template #default="scope">
          <el-input v-model="scope.row.updateDeliveryTimeReason" placeholder="请输入"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="90">
        <template #default="scope">
          <div v-if="scope.row.start === 1">
          <!-- v-if="scope.row.itemNumber.substring(0,2)==='57'" -->
            <el-button v-if="scope.row.itemNumber.substring(0,2)==='57' || scope.row.bednr==='计划外' || true" size="mini" type="text" @click="handCopy(scope.$index, scope.row)">拆分</el-button>
            <el-button size="mini" type="text" @click="handDelete(scope.$index, scope.row)">删除</el-button>
          </div>
          <div v-if="scope.row.start === 2">
            <el-button size="mini" type="text" @click="handRemove(scope.$index, scope.row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'PurchaseBody',
  props: ['tabData', 'selectTabData'],
  data () {
    return {
      purchaseOrderNumber: '',
      havebz: false,
      newSelectTabData: []
    }
  },
  created () {
    if (this.newSelectTabData.length > 0 &&
    ((this.newSelectTabData[0].newDeliveryDate && this.newSelectTabData[0].newDeliveryDate !== '') ||
    (this.newSelectTabData[0].actualDeliveryDate && this.newSelectTabData[0].actualDeliveryDate !== ''))) {
      this.havebz = true
    }
  },
  mounted () {

  },
  methods: {
    dateFocus (row) {
      if (!row.olddate) {
        if (row.newDeliveryDate && row.newDeliveryDate !== '') {
          row.olddate = row.newDeliveryDate
        } else if (row.actualDeliveryDate && row.actualDeliveryDate !== '') {
          row.olddate = row.actualDeliveryDate
        }
      }
    },
    dateChange (row) {
      let t = 0
      for (const taprow of this.newSelectTabData) {
        if (dayjs(new Date(row.newDeliveryDate)).format('YYYY-MM-DD') === dayjs(new Date(taprow.newDeliveryDate)).format('YYYY-MM-DD')) {
          t = t + 1
        }
      }
      /* if (t > 1) {
        this.$message({
          showClose: true,
          message: '实际交付日期重复，请查证！',
          type: 'error'
        })
        row.newDeliveryDate = ''
        return false
      } */
      /* const dateDif = row.newDeliveryDate.getTime() - new Date(sessionStorage.getItem('serversDate')).getTime()
      if (dateDif / 1000 / 60 / 60 / 24 / 365 > 1) {
        row.newDeliveryDate = null
        this.$message({
          showClose: true,
          message: '请选择一年内的日期',
          type: 'error'
        })
      } */
      if (this.havebz && (new Date(row.newDeliveryDate).getTime() - new Date(row.olddate).getTime()) / 1000 / 60 / 60 / 24 <= 14) {
        row.mustbz = true
      } else {
        row.mustbz = false
      }
    },
    // 点击拆分
    handCopy (index, row) {
      request('/api/deliver/deliver/verifyDel', 'POST', row).then((res) => {
          if (res.code === '200') {
            if (res.data) {
              var newData = { ...row }
              newData.start = 2
              newData.deliveryQuantity = ''
              newData.btnShow = 'hiden'
              this.newSelectTabData.splice(index + 1, 0, {
                ...newData
              })
            } else {
              this.$message({
                showClose: true,
                message: '交付计划行存在未响应送货需求，请先取消送货需求再修改交付计划',
                type: 'error'
              })
            }
          }
      })
      
    },
    // 拆分后删除
    handRemove (index, row) {
      if (this.newSelectTabData.length === 1) {
        this.$message({
          showClose: true,
          message: '至少保留一条数据',
          type: 'warning'
        })
      } else {
        console.log(row)
        request('/api/deliver/deliver/verifyDel', 'POST', row).then((res) => {
          if (res.code === '200') {
            if (res.data) {
              this.newSelectTabData.splice(index, 1)
            } else {
              this.$message({
                showClose: true,
                message: '交付计划行存在未响应送货需求，请先取消送货需求再修改交付计划',
                type: 'error'
              })
            }
          }
        })
      }
    },
    // 删除原数据
    handDelete (index, row) {
      if (this.newSelectTabData.length === 1) {
        this.$message({
          showClose: true,
          message: '至少保留一条数据',
          type: 'warning'
        })
      } else {
        console.log(row)
        request('/api/deliver/deliver/verifyDel', 'POST', row).then((res) => {
          if (res.code === '200') {
            if (res.data) {
              this.newSelectTabData.splice(index, 1)
            } else {
              this.$message({
                showClose: true,
                message: '交付计划行存在未响应送货需求，请先取消送货需求再修改交付计划',
                type: 'error'
              })
            }
          }
        })
      }
    }
  },
  watch: {
    selectTabData (newVal) {
      this.purchaseOrderNumber = newVal[0].purchaseOrderNumber
      this.newSelectTabData = newVal
      if ((newVal.length > 0 && newVal[0].newDeliveryDate && newVal[0].newDeliveryDate !== '') ||
      (newVal.length > 0 && newVal[0].actualDeliveryDate && newVal[0].actualDeliveryDate !== '')) {
        this.havebz = true
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .cgdbh{
    margin-bottom: 20px;
    font-size: 16px;
  }
  .tableNav{
    border: none;
  }
  .tableList{
    padding: 0 20px;
  }
  .roleTab .tableNav :deep(.searchList .col){
    min-width: 250px;
  }
  .roleTab .tableNav :deep(.searchList .col .title){
    min-width: 42px;
  }
  .apply-table .el-textarea{
    padding: 20px 20px 0;
    width: calc(100% - 40px);
  }
  .table-time .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
  }
</style>
